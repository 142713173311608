import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from '../locales/en/translation';
import translationJP from '../locales/jp/translation';
import translationVI from '../locales/vi/translation';

// the translations
const resources = {
    vi: {
        translation: translationVI
    },
    en: {
        translation: translationEN
    },
    jp: {
      translation: translationJP
  }
};

const language = 'vi';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources,
    lng:language,
    fallbackLng: "vi",
    interpolation: {
      escapeValue: false 
    }
  });

export default i18n;
