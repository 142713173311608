import React from "react";
import { useForm } from "react-hook-form";

import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onsubmit = (value) => {
    emailjs.init("C_dSinSxaDdOfYULB");
    emailjs.send("service_wsgqwjx", "template_5ldy647", {
      name: value?.name,
      subject: value?.subject,
      desc: value?.desc,
      email: value?.email,
    });
    console.log(value);
  };

  return (
    <div>
      <div className="yeo-footers" id="contact">
        <div className="container">
          <div className="footer-item">
            <div className="send-mail foter-active">
              <form onSubmit={handleSubmit(onsubmit)}>
                <p>{t("FORM.TITLE_1")}</p>
                <input
                  {...register("name", {
                    required: t("MESSAGE_ERROR.ERRORS_NAME"),
                  })}
                  type="text"
                />
                <span className="text-errors">
                  {errors.name && errors.name.message}
                </span>
                <p>{t("FORM.TITLE_2")}</p>
                <input
                  {...register("email", {
                    required: t("MESSAGE_ERROR.ERROS_EMAIL"),
                  })}
                  type="email"
                />
                <span className="text-errors">
                  {errors.email && errors.email.message}
                </span>
                <br />
                <p>{t("FORM.TITLE_3")}</p>
                <input
                  {...register("subject", {
                    required: t("MESSAGE_ERROR.ERROS_SUBJECT"),
                  })}
                  type="text"
                />
                <span className="text-errors">
                  {errors.subject && errors.subject.message}
                </span>
                <br />
                <p>{t("FORM.TITLE_4")}</p>
                <textarea {...register("desc")} type="text" /> <br />
                <button className="btn btn-secondary">
                  {t("FORM.TITLE_5")}
                </button>
              </form>
            </div>
            <div className="foter-active">
              {" "}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.0410836889164!2d105.77825437512924!3d21.031042080618892!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31345573ed7a7315%3A0xf954c36df13c26b9!2sDreamland%20Bonanza!5e0!3m2!1sen!2s!4v1672765443421!5m2!1sen!2s"
                width={600}
                height={450}
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
          <div className="info foter-active">
            <div className="columns">
              {" "}
              <div className="column col-3 col-sm-3">
                <div className="yeo-footer-content">
                  <h4 className="info-title">{t("FOOTER.DESC_1")}</h4>
                  <ul className="nav">
                    <li className="nav-item">
                      <a href="#">DevOps</a>
                    </li>
                    <li className="nav-item">
                      <a href="#">{t("FOOTER.DESC_2")}</a>
                    </li>
                    <li className="nav-item">
                      <a href="#">{t("FOOTER.DESC_3")}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="column col-3 col-sm-3">
                <div className="yeo-footer-content">
                  <h4 className="info-title">{t("FOOTER.DESC_4")}</h4>
                  <ul className="nav">
                  <li className="nav-item">
                      <a href="#">Asia Travel</a>
                    </li>
                    <li className="nav-item">
                      <a href="#">Evotek</a>
                    </li>
                    <li className="nav-item">
                      <a href="#">JTS</a>
                    </li>
                    <li className="nav-item">
                      <a href="#">{t("FOOTER.DESC_5")}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="column col-3 col-sm-3">
                <div className="yeo-footer-content">
                  <h4 className="info-title-item">{t("FOOTER.DESC_6")} @C2i</h4>
                  <ul className="nav">
                    <li className="nav-item">
                      <a href="#">
                        Hotline Whatapps / Telegram : + 84903246045
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="https://c2i.asia">C2i {t("FOOTER.DESC_7")}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="column col-3 col-sm-3">
                <div className="yeo-footer-content">
                  <h4 className="info-title-item">{t("FOOTER.DESC_8")}</h4>
                  <ul className="nav">
                    <li className="nav-item">
                      <a href="mailto:support@1tour.asia?subject=Interest 1tour_CRM&body=I am looking for 1tour CRM">Support@1tour.asia</a>
                    </li>
                    <li className="nav-item">
                      <a href="#">Twitter</a>
                    </li>
                    <li className="nav-item">
                      <a href="https://www.facebook.com/1tourcrm">Facebook</a>
                    </li>
                    <li className="nav-item">
                      <a href="https://zalo.me/436591172410499240">Zalo: +84903246045</a>
                    </li>
                    <li className="nav-item">
                      <a href="#">Github</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
