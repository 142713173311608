/* eslint-disable jsx-a11y/anchor-is-valid */
import "./App.css";
import { Route, Routes } from "react-router-dom";
import WebsiteLayout from "./layouts/WebsiteLayout";
import HomePage from "./page/HomePage";
import Product from "./page/Product";
import "react-toastify/dist/ReactToastify.css";
// import { useEffect } from "react";


function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<WebsiteLayout />}>
          <Route index element={<HomePage />} />
          <Route path="/product" element={<Product />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
