import React from 'react'
import Footer from '../component/Footer'
import Header from '../component/Header'



const Product = () => {
  return (
    <div>
     <main>Product</main>
    </div>
  )
}
export default Product