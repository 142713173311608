import Footer from "../component/Footer";
import Header from "../component/Header";
import { Outlet } from "react-router-dom";
import { useEffect } from "react";
function WebsiteLayout(chilrdren) {

    useEffect(()=> {
        // <script>
        var chatbox = document.getElementById('fb-customer-chat');
        chatbox.setAttribute("page_id", "192178930646480");
        chatbox.setAttribute("attribution", "biz_inbox");
      // </script>
    
      // <!-- Your SDK code -->
      // <script>
        window.fbAsyncInit = function() {
          window.FB.init({
            xfbml            : true,
            version          : 'v18.0'
          });
        };
    
        (function(d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s); js.id = id;
          js.src = 'https://connect.facebook.net/vi_VN/sdk/xfbml.customerchat.js';
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
      // </script>
      },[]);

    return ( 
        <div>
            <Header/>
            <Outlet/>
            <Footer/>

            {/* // <!-- Facebook chatbot -->
            // <!-- Messenger Chat Plugin Code --> */}
            <div id="fb-root"></div>

            {/* // <!-- Your Chat Plugin code --> */}
            <div id="fb-customer-chat" className="fb-customerchat">
            </div>
        </div>


    );
}

export default WebsiteLayout;