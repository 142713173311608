export default [
  {
    image: "./images/team6.jpg",
    name: "PRICELIST_OPTION1_TITLE",
    position: "PRICELIST_OPTION1_SHORT_DESC",
    linkedIn: "#",
    telegram: "#",
    skill1: "",
    skill2: "PRICELIST_OPTION1_DESC",
    skill3: "",
  },
  {
    image: "./images/team1.jpg",
    name: "PRICELIST_OPTION2_TITLE",
    position: "PRICELIST_OPTION2_SHORT_DESC",
    linkedIn: "#",
    telegram: "#",
    skill1: "",
    skill2: "PRICELIST_OPTION2_DESC",
    skill3: "",
  },
  {
    image: "./images/team3.jpg",
    name: "PRICELIST_OPTION3_TITLE",
    position: "PRICELIST_OPTION3_SHORT_DESC",
    linkedIn: "#",
    telegram: "#",
    skill1: "",
    skill2: "PRICELIST_OPTION3_DESC",
    skill3: "",
  },
  // {
  //   "image": "./images/team4.jpg",
  //   "name": "Mai",
  //   "position": "ARTIST",
  //   "linkedIn": "#",
  //   "telegram": "#"
  // },
  // {
  //   "image": "./images/team5.jpg",
  //   "name": "Huy Tung",
  //   "position": "APPLICATION_DEVOLOPMENT",
  //   "linkedIn": "#",
  //   "telegram": "#"
  // },
  // {
  //   "image": "./images/team6.jpg",
  //   "name": "Minh Duc",
  //   "position": "QC",
  //   "linkedIn": "#",
  //   "telegram": "#"
  // }
];
